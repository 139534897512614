const services = [
  "Corporate Visa Counselling",
  "Corporate Travel Insurance",
  "Corporate Travel",
  "Corporate Ticketing and Reservations",
  "Corporate International Conferences / Fairs",
  "Corporate immigration Services",
  "Corporate Expatriate Management",
];

export default services;
